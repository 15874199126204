/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import buildUrl from 'build-url';

import { getNavigationProps } from '../../utils/navigation';
import Button from '../../atoms/button';
import Picture from '../../atoms/cms-picture';
import Typography from '../../atoms/typography';

import Arrow from '../../atoms/icon-arrow';

const GiftFinderWrapper = styled.div`
  position: relative;
`;

const GiftBodyWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  position: absolute;
  padding-top: ${({ theme }) => theme.modularScale.sixteen};
  max-height: 500px;
  height: 390px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      padding-top: ${theme.modularScale.thirtyTwo};
      height: 321px;
    }
  `}
`;

const TitleWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.modularScale.eight};
  z-index: ${({ theme }) => theme.layers.backstage + 1};
  margin-left: ${({ theme }) => theme.modularScale.sixteen};

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      width: 55vw;
      justify-content: flex-start;
      margin-left: ${theme.modularScale.thirtyTwo};
      margin-bottom: ${theme.modularScale.sixteen};
    }
  `}
`;

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  color: ${({ theme }) => theme.color.white};
  text-align: left;
  z-index: ${({ theme }) => theme.layers.backstage + 1};
  position: relative;
  line-height: 1;
  max-width: 75vw;
  padding-right: ${({ theme }) => theme.modularScale.thirtyTwo};

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      max-width: none;
    }
  `}
`;

const Step = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0.15em;
  padding-right: ${({ theme }) => theme.modularScale.sixteen};

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      padding-right: 0;
    }
  `}
`;

const FILTERED_SUBTITLE_EMO_PROPS = new Set(['showAsSquare', 'startButton']);

const SubTitle = styled(Typography, {
  shouldForwardProp: (prop) => !FILTERED_SUBTITLE_EMO_PROPS.has(prop)
})`
  color: ${({ theme }) => theme.color.white};
  padding-bottom: ${({ theme }) => theme.modularScale.thirtyTwo};
  padding-left: ${({ theme }) => theme.modularScale.sixteen};
  text-align: left;
  line-height: 35px;
  z-index: ${({ theme }) => theme.layers.backstage + 1};
  position: relative;
  line-height: 1;
  max-width: 75vw;

  ${({ theme, showAsSquare, startButton }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      padding-left: ${theme.modularScale.thirtyTwo};
      padding-bottom: ${startButton ? theme.modularScale.fortyFour : theme.modularScale.twentyFour};
      max-width: 70vw;
    }
  `}
`;

const FILTERED_THEMEGRID_PROPS = new Set(['showAsSquare', 'themeGrid']);

const GiftFinderActions = styled('span', {
  shouldForwardProp: (prop) => !FILTERED_THEMEGRID_PROPS.has(prop)
})`
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.layers.backstage + 1};
  position: relative;

  /* for ipad & desktop */
  ${({ theme, showAsSquare, themeGrid }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      ${themeGrid && `padding-right: ${theme.modularScale.sixteen};`}
    }
  `}
`;

const ActionWrapper = styled('span', {
  shouldForwardProp: (prop) => !FILTERED_THEMEGRID_PROPS.has(prop)
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.modularScale.sixteen};
  overflow-x: auto;
  ${({ theme, themeGrid }) => (!themeGrid && `margin-right: ${theme.modularScale.sixteen};`)}

  ${({ showAsSquare, theme, themeGrid }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      flex-direction: row;
      margin-left: ${theme.modularScale.thirtyTwo};
      margin-right: ${!themeGrid ? theme.modularScale.thirtyTwo : '0px'};
    }
  `}
`;

const StartButton = styled(Button, {
  shouldForwardProp: (prop) => !FILTERED_THEMEGRID_PROPS.has(prop)
})`
  width: 167px;
  font-size: ${({ theme }) => theme.modularScale.sixteen};
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.sans};
  color: ${({ theme }) => theme.color.bluePrimary};
  padding: 0;
`;

const ButtonGrid = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  display: grid;
  width: 100%;
  grid-gap: 8px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(${({ priceMobile }) => priceMobile ? 2 : 4}, 1fr);

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(4, 1fr);
    }
  `}
`;

const ButtonFlexBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  display: flex;
  width: 150vw;
  flex-wrap: wrap;
  overflow-x: scroll;
  gap: 8px;
  max-height: 142px;

  /* for ipad & desktop */
  ${({ theme, showAsSquare }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      overflow-x: none;
    }
  `}
`;

const OptionButton = styled(Button, {
  shouldForwardProp: (prop) => !FILTERED_THEMEGRID_PROPS.has(prop)
})`
  font-size: ${({ theme }) => theme.modularScale.sixteen};
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.sans};
  color: ${({ theme }) => theme.color.bluePrimary};
  height: 42px;
  text-transform: none;
  letter-spacing: 0;
  padding: 0 5px;

  :hover {
    opacity: 1;
  }

  /* for ipad & desktop */
  ${({ theme, showAsSquare, themeGrid }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      ${themeGrid && css`
        padding: 0 12px;
      `}

      :hover {
        opacity: 0.75;
      }
    }
  `}
`;

const Wayfinder = styled('span', {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  position: absolute;
  bottom: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: ${({ theme }) => theme.modularScale.large};
  max-width: 60vw;
  z-index: ${({ theme }) => theme.layers.backstage + 1};

  ${({ showAsSquare, theme }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      margin-left: ${theme.modularScale.thirtyTwo};
    }
  `}
`;

const BackButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${({ theme }) => theme.modularScale.sixteen};
  font-family: ${({ theme }) => theme.font.caption};
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};

  span {
    margin-left: 0.25rem;
  }

  ${({ showAsSquare, theme }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
    }
  `}
`;

const BackArrow = styled(Arrow)`
  height: 1.4rem !important;
  width: 1.4rem !important;
  fill: ${({ theme }) => theme.color.white};
  transform: rotate(180deg);
  margin-right: 0.25em;
`;

const GiftImageMobile = styled(Picture, {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  margin-left: auto;
  margin-right: auto;
  z-index: ${({ theme }) => theme.layers.backstage};
  width: 100%;
  ${({ showAsSquare, theme }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      display: none;
    }
  `}
`;

const GiftImageDesktop = styled(Picture, {
  shouldForwardProp: (prop) => prop !== 'showAsSquare'
})`
  display: none;
  z-index: ${({ theme }) => theme.layers.backstage};
  ${({ showAsSquare, theme }) => !showAsSquare && css`
    @media screen and (min-width: ${theme.breakpoint.small}) {
      display: block;
      width: 100%;
    }
  `}
`;

const GiftFinder = ({ data }) => {
  const router = useRouter();
  const [finderStep, setFinderStep] = useState(0);
  const [giftUrl, setGiftUrl] = useState('');
  const [ageRangeVal, setAgeRangeVal] = useState('default');
  const [priceRangeVal, setPriceRangeVal] = useState('default');
  const [showAsSquare] = useState(data?.square_view ?? false);

  const ageOptions = [
    ['0 - 6M', '0-6m'],
    ['6 - 12M', '6-12m'],
    ['12 - 24M', '12-24m'],
    ['2 - 4Y', '2-4y'],
    ['4 - 6Y', '4-6y'],
    ['6 - 8Y', '6-8y'],
    ['8Y+', '8y+'],
    ['All ages', 'all']
  ];

  const priceOptions = [
    ['Under $50', '50'],
    ['Under $100', '100'],
    ['Under $200', '200'],
    ['Show all prices', 'all']
  ];

  const subtitleText = [
    data?.starting_subtitle
    ?? 'Find the perfect gift for kids based on their age and interests!',
    data?.age_subtitle ?? 'What is the child\'s age?',
    data?.price_subtitle ?? 'How much would you like to spend?',
    data?.theme_subtitle ?? 'What type of toy is the child most interested in?'
  ];

  useEffect(() => {
    if (giftUrl !== '') {
      const item = { navigation_url: giftUrl };
      const nextURL = getNavigationProps({ item });
      router.push(nextURL.href, nextURL.as);
    }
  }, [giftUrl]);

  const handleStepChange = (stepNumber, themeVal = 'default') => {
    if (stepNumber === 4) {
      const allThemes = themeVal === 'default' || themeVal === 'all';
      const allAges = ageRangeVal === 'default' || ageRangeVal === 'all';
      const allPrices = priceRangeVal === 'default' || priceRangeVal === 'all';
      const isDefault = allAges && allPrices && allThemes;

      if (isDefault) {
        setGiftUrl(data?.default_url ?? 'shop');
      }

      const finalQueries = {};

      const themeUrl = new URL((themeVal && allThemes) ? '/shop' : themeVal, process.env.NEXT_PUBLIC_CLIENT_HOST);

      // if the theme URL already has applied facets,
      // we will need to ensure we're maintaining those facets
      if (themeUrl.search.length) {
        const themeParams = new URLSearchParams(themeUrl.search);

        themeParams.forEach((facetValue, facetType) => {
          finalQueries[facetType] = facetValue;
        });
      }

      if (!allAges) finalQueries['variants.age_range'] = ageRangeVal.replace('+', '%2B');
      if (!allPrices) finalQueries['variants.maisonette_sale'] = `max:${priceRangeVal}`;

      const newUrl = buildUrl('', {
        path: themeUrl.pathname,
        queryParams: finalQueries
      });

      setGiftUrl(newUrl);
    } else {
      setFinderStep(stepNumber);
    }
  };

  const renderButtons = () => {
    switch (finderStep) {
      case 0:
        return (
          <StartButton
            type="button"
            green
            showAsSquare={showAsSquare}
            onClick={() => handleStepChange(finderStep + 1)}
          >
            {data.starting_cta}
          </StartButton>
        );
      case 1:
        return (
          <ButtonGrid>
            {ageOptions.map(([buttonText, queryValue]) => (
              <OptionButton
                key={`ageOption${queryValue}`}
                showAsSquare={showAsSquare}
                onClick={() => {
                  setAgeRangeVal(queryValue);
                  handleStepChange(finderStep + 1);
                }}
              >
                {buttonText}
              </OptionButton>
            ))}
          </ButtonGrid>
        );
      case 2:
        return (
          <ButtonGrid priceMobile>
            {priceOptions.map(([buttonText, queryValue]) => (
              <OptionButton
                key={`priceOption${queryValue}`}
                showAsSquare={showAsSquare}
                onClick={() => {
                  setPriceRangeVal(queryValue);
                  handleStepChange(finderStep + 1);
                }}
              >
                {buttonText}
              </OptionButton>
            ))}
          </ButtonGrid>
        );
      case 3:
        return (
          <ButtonFlexBox>
            {Object.values(data.elements ?? []).map((theme) => (
              <OptionButton
                key={`themeOption${theme.display}`}
                showAsSquare={showAsSquare}
                themeGrid
                onClick={() => {
                  handleStepChange(finderStep + 1, theme.value);
                }}
              >
                {theme.display}
              </OptionButton>
            ))}
          </ButtonFlexBox>
        );
      default:
        return null;
    }
  };

  return (
    <GiftFinderWrapper>
      <GiftBodyWrapper>
        <TitleWrapper>
          <Title
            element="h1"
            like="heading-7"
            showAsSquare={showAsSquare}
          >
            {data.heading}
          </Title>
          {finderStep > 0 && (<Step element="p" like="label-6">{`${finderStep} of 3`}</Step>)}
        </TitleWrapper>
        <SubTitle
          element="h2"
          like="dec-3"
          showAsSquare={showAsSquare}
          startButton={finderStep === 0}
        >
          {subtitleText[finderStep]}
        </SubTitle>
        <GiftFinderActions themeGrid={finderStep === 3}>
          <ActionWrapper showAsSquare={showAsSquare} themeGrid={finderStep === 3}>
            {renderButtons()}
          </ActionWrapper>
        </GiftFinderActions>
      </GiftBodyWrapper>
      <Wayfinder showAsSquare={showAsSquare}>
        {finderStep > 0 && (
          <BackButton
            type="button"
            styledLikeLink
            showAsSquare={showAsSquare}
            onClick={() => handleStepChange(finderStep - 1)}
          >
            <BackArrow />
            <span>BACK</span>
          </BackButton>
        )}
      </Wayfinder>
      <GiftImageMobile
        {...data.image_mobile}
        alt={data.image_alt}
      />
      <GiftImageDesktop
        {...data.image_desktop}
        alt={data.image_alt}
        giftFinderDesktop
      />
    </GiftFinderWrapper>
  );
};

GiftFinder.propTypes = {
  data: PropTypes.object.isRequired
};

export default GiftFinder;
